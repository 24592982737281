/******************************************************************************/
/* TREES                                                                      */
/******************************************************************************/

ul.tree.condition > li {
  &:before {
    top: 0;
    height: calc(1em + 6px);
  }
}
