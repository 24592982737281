/******************************************************************************/
/* PROGRESS                                                                   */
/******************************************************************************/

.progress {
  .progress-bar {
    .progress-bar-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.5rem;
    }
  }
}
