/******************************************************************************/
/* VARIABLES                                                                  */
/******************************************************************************/

$hbm-font-size: 14px;
$hbm-font-family: 'Arial', 'Neue Helvetica', 'Helvetica', sans-serif;


// Prepare @burdanews/hbm_bn_styles variables
$burda-color: #59cbe8;

$hbm-color-bg: #e5e5e5;
$hbm-color-text: #260211;
$hbm-color-logo: saturate(lighten($burda-color, 8%), 60%);
$hbm-color-highlight: darken(saturate($burda-color, 10%), 5%);
$hbm-color-secondary: lighten($burda-color, 20%);

$hbm-toggable-items: ('ids', 'uploads', 'entities', 'blocks', 'snippets', 'pages', 'general');

$hbm-colors: (
  muted: #6c757d,
);

// Prepare bootstrap variables
$theme-colors: (
  primary: $hbm-color-highlight,
  secondary: $hbm-color-secondary,
);

$font-family-base: $hbm-font-family;
$headings-font-family: $hbm-font-family;

$body-color: $hbm-color-text;

/******************************************************************************/

$border-radius:               0rem !default;
$border-radius-lg:            0rem !default;
$border-radius-sm:            0rem !default;

$input-border-radius:         0rem !default;
$input-border-radius-lg:      0rem !default;
$input-border-radius-sm:      0rem !default;

$jumbotron-bg: $hbm-color-bg;
