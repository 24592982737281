/******************************************************************************/
/* DASHBOARD                                                                  */
/******************************************************************************/
.hbm-logo-responsive {
  font-size: 2.1rem;
}

.hbm-logo-responsive-list {
  font-size: 1.4rem;
}

.responsive-icons {
  font-size: 1.1rem;
}

.responsive-headline {
  font-size: 1.5rem;
}

@include media-breakpoint-up(sm) {
  .hbm-logo-responsive {
    font-size: 2.5rem;
  }

  .hbm-logo-responsive-list {
    font-size: 2.0rem;
  }

  .responsive-headline {
    font-size: 2.0rem;
  }

  .responsive-icons {
    font-size: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  .hbm-logo-responsive {
    font-size: 3rem;
  }

  .responsive-headline {
    font-size: 2.2rem;
  }

  .responsive-icons {
    font-size: 2.0rem;
  }
}

@include media-breakpoint-up(lg) {
  .responsive-headline {
    font-size: 2.7rem;
  }

  .responsive-icons {
    font-size: 2rem;
  }
}

@include media-breakpoint-up(xl) {
  .hbm-logo-responsive {
    font-size: 3.6rem;
  }
}