/******************************************************************************/
/* STEPS                                                                      */
/******************************************************************************/

ul.steps {
  li.step {
    .step-text {
      line-height: 1;
    }

    a.step-item:hover {
      .step-icon {
        background-color: darken(theme-color("300"), 5%);
      }
    }
    &.step-active {
      a.step-item:hover {
        .step-icon {
          background-color: darken(theme-color("primary"), 5%);
        }
      }
    }

    .step-item {
      .step-icon {
        color: $hbm-color-text;
        background-color: theme-color("300");
      }
    }


    &.step-inaccessible {
      .step-text {
        opacity: 0.4;
      }

      .step-item {
        .step-icon {
          color: transparentize($hbm-color-text, 0.6);
          background-color: #fff;
        }
      }
    }

    &.step-active {
      .step-item {
        .step-icon {
          color: #fff;
          background-color: theme-color("primary");
        }
      }
    }
  }
}

/******************************************************************************/
/* SECTIONS                                                                   */
/******************************************************************************/

ul.sections {
  li.step {
    .step-text {
      line-height: 1;
    }
  }
}
