/******************************************************************************/
/* TAGIFY                                                                     */
/******************************************************************************/

.datagrid-menu-search .tagify__tag > div > .tagify__tag-text {
  white-space: nowrap;
}

// Fix bug in chrome where ::after does not get removed on input click
.tagify__input::after {
  content: none !important;
}