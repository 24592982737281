/******************************************************************************/
/* LAYOUT                                                                     */
/******************************************************************************/

//a:hover {
//  .hbmlp-logo-icon {
//    .responsive-svg:nth-of-type(2) {
//      background: linear-gradient(90deg, #FF0018, #FFA52C, #FFFF41, #008018, #0000F9, #86007D);
//      fill: transparent;
//    }
//  }
//}

/******************************************************************************/

#hbmin-logo {
  flex: 0 0 250px;
  padding: 15px 0.75rem;

  // This will scale the icons and the text.
  font-size: 2rem;

  @include media-breakpoint-up(xl) {
    flex: 0 0 300px;
    padding: 15px 0.75rem;
  }

  &:hover {
    text-decoration: none;
  }
}

/******************************************************************************/

#hbmin-all {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #hbmin-header {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    background-color: $hbm-color-bg;

    .navbar {
      flex: 1 1 auto;
      padding: 0.5rem 1rem 0.5rem 0;

      .nav {
        font-family: $hbm-font-family;
        @include font-size(1.85rem);

        .nav-item {
          margin: 0 0.4rem;
          &:first-child {
            margin-left: 0;
          }

          .nav-link {
            padding: 0.1rem 0.25rem;
          }

          &.active {
            .nav-link {
              padding: 0.1rem 1rem;

              @include border-radius($nav-pills-border-radius);

              @include plain-hover-focus {
                color: $nav-pills-link-active-color;
                cursor: default;
                background-color: $nav-pills-link-active-bg;
              }
            }
          }
        }

      }
    }

    #hbmin-profile {
      flex: 0 1 auto;
      padding: 0.25rem 0.75rem;
      text-align: center;

      #hbmin-profile-links {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        > li {
          margin: 0;
          padding: 0;
          line-height: 1;

          > a {
            text-wrap: none;
          }

          &:not(:last-child) {
            margin-right: 5px;
            padding-right: 5px;
            border-right: 1px solid $hbm-color-text;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      #hbmin-logo {
        flex: 1 0 50%;
        order: 1;

        .responsive-svg {
          max-width: 220px;
        }
      }

      #hbmin-profile {
        flex: 0 0 50%;
        order: 2;
      }

      .navbar {
        flex: 0 0 100%;
        order: 3;

        .nav {
          justify-content: center;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      #hbmin-logo {
        flex: 0 0 100%;
        justify-content: center;
      }

      #hbmin-profile {
        flex: 0 0 100%;
      }

      .navbar {
        .nav {
          font-size: 1.5rem;
        }
      }
    }
  }

  #hbmin-main {
    flex: 1 1 auto;
    display: flex;

    #hbmin-aside {
      position: relative;

      flex: 0 0 250px;
      max-width: 250px;
      @include media-breakpoint-up(xl) {
        flex: 0 0 300px;
        max-width: 300px;
      }
      background-color: $hbm-color-bg;
      padding: 0 0.75rem 0.75rem 0.75rem;

      .nav {
        .nav-item {
          width: 100%;
          max-width: 100%;
          margin-bottom: $spacer/4;
        }
      }
    }

    #hbmin-content {
      flex: 1 1 auto;
      margin-top: 1rem;
      overflow: auto;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      #hbmin-aside {
        flex: 0 0 auto;
        max-width: 100%;
      }

      #hbmin-content {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }

  #hbmin-footer {
    flex: 0 1 auto;
    background-color: $hbm-color-bg;

    .navbar {
      font-size: 0.8em;
      padding: 5px 0.75rem;

      .navbar-brand {
        width: 50px;
        padding: 0;
      }

      .navbar-nav .nav-link {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
